import { Sales } from './pages/Sales/Sales';
import { Layout } from '@arco-design/web-react';
const App = () => {
    return (
        <Layout style={{ height: "100vh", width: "100vw" }}>
            <Sales />
        </Layout>
    );
}


export default App;

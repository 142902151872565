import React, { useState } from 'react';
import { Input, Button, Space, Layout } from '@arco-design/web-react';
import { IconEdit ,IconSave} from '@arco-design/web-react/icon';

const { TextArea } = Input;

const RoleConfig = () => {
    const [roleName, setRoleName] = useState('小希老师');
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
    };

    return (
        <Layout style={{ padding: "10px", width:"38vw" }}>
            <Space direction='horizontal'>
                <Space align='center'>
                    {isEditing ? (
                        <Input
                            value={roleName}
                            onChange={(v) => setRoleName(v)}
                            style={{ height: '40px' }}

                        />
                    ) : (
                        <h1 style={{
                            height: '40px', // 固定高度
                            lineHeight: '40px', // 确保文本在容器内垂直居中
                            margin: 0 // 去除默认的外边距
                        }}>{roleName}</h1>
                    )}
                    <Button type='text'
                            size={"large"}
                            icon={isEditing ? <IconSave /> : <IconEdit />}
                            onClick={isEditing ? handleSaveClick : handleEditClick}>
                    </Button>
                </Space>
                {/*<Button type="primary">*/}
                {/*    发布*/}
                {/*</Button>*/}
            </Space>
            <TextArea
                placeholder="请输入内容"
                style={{ width: '100%', height: '88vh', marginTop: '1vh' }}
            />
        </Layout>
    );
};

export default RoleConfig;

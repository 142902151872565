import React from 'react';
import { Menu } from '@arco-design/web-react';
import {
    IconMessage,
} from '@arco-design/web-react/icon';

const PlatformList = () => {
    return (
        <Menu
            style={{marginTop:"1vh"}}
            defaultSelectedKeys={['1']}
        >
            <Menu.Item key="1">
                <IconMessage />
                聊天测试
            </Menu.Item>
            {/*<Menu.Item key="2">*/}
            {/*    <IconUserGroup />*/}
            {/*    员工列表*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="3">*/}
            {/*    <IconUser />*/}
            {/*    人才市场*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="4">*/}
            {/*    <IconBook />*/}
            {/*    知识管理*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="5">*/}
            {/*    <IconDashboard />*/}
            {/*    数据监控*/}
            {/*</Menu.Item>*/}
        </Menu>
    );
};

export default PlatformList;

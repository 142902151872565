import React, { useEffect, useState, useRef } from 'react';
import {
    Input,
    Button,
    Avatar,
    Typography,
    Tooltip,
    Space,
    Layout,
    Radio,
    Empty
} from '@arco-design/web-react';
import {
    IconSend,
} from '@arco-design/web-react/icon';
import { getBotInfo, postChatBot, createConversation, getConversationMessages, chatRetrieve } from '../../../api/api';

const { Text } = Typography;

const ChatInterface = () => {
    const options = [
        {
            value: 'person',
            label: '拟人回复',
        },
        {
            value: 'quick',
            label: '快速回复',
        }
    ];
    const [conversationId, setConversationId] = useState("");
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [replyMode, setReplyMode] = useState('quick');
    const contentRef = useRef(null);
    const disableValue = useRef(false)

    useEffect(() => {
        setConversationId(JSON.parse(localStorage.getItem('conversation_id')))
        if (JSON.parse(localStorage.getItem('conversation_id'))) {
            getConversationMessages(JSON.parse(localStorage.getItem('conversation_id'))).then(data => {
                setMessages(data.data)
            })
        } else {
            setMessages([]);
            newConversationHandler()
        }

    }, []);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSend = async () => {
        if (inputValue.trim()) {
            setInputValue("");
            // 发送消息
            disableValue.current = true
            postChatBot(conversationId, inputValue).then(data => {
                getMessages(conversationId)
                // 检查对话状态
                const checkStatus = async (chatId) => {
                    const intervalId = setInterval(async () => {
                        try {
                            const response = await chatRetrieve(conversationId, chatId);
                            if (response.data.status === 'completed') {
                                clearInterval(intervalId); // 停止轮询
                                // 处理完成后的逻辑
                                getMessages(conversationId); // 重新获取消息
                            }
                        } catch (error) {
                            console.error('轮询 chatRetrieve 时出错:', error);
                            clearInterval(intervalId); // 如果发生错误也停止轮询
                        }
                    }, 1000); // 每秒调用一次
                };

                // 调用轮询函数
                checkStatus(data.data.id);
            });
        }
    };



    // 新建会话
    const newConversationHandler = () => {
        setMessages([])
        // 获取机器人信息拿到开场白
        getBotInfo().then(data => {
            if (data.data.onboarding_info.prologue) {
                // 新建对话
                createConversation([{
                    "role": "assistant",
                    "content": data.data.onboarding_info.prologue,
                    "content_type": "text"
                }]).then(data => {
                    getMessages(data.data.id)
                    setConversationId(data.data.id)
                    localStorage.removeItem("conversation_id")
                    localStorage.setItem("conversation_id", JSON.stringify(data.data.id))
                })
            }
        })
    }

    // 获取消息

    const splitAndLogSentences = (text) => {
        return text.split(/(?<=[。？！])/).map(sentence => sentence.trim()).filter(sentence => sentence);
    };

    const getMessages = (conversationid) => {
        getConversationMessages(conversationid).then(data => {
            setMessages(prevMessages => {
                if (prevMessages.length > 0 && prevMessages[0].id === data.data[0].id) {
                    return prevMessages;
                } else {
                    const msg = data.data[0];
                    if (msg.role === "assistant") {
                        if (msg.content) {
                            const splitMessages = splitAndLogSentences(msg.content);
                            const splitMsgObjects = splitMessages.map(sentence => ({
                                ...msg,
                                content: sentence,
                            }));

                            if (replyMode === 'quick') {
                                //逐句展示
                                splitMsgObjects.forEach((message, index) => {
                                    setTimeout(() => {
                                        setMessages(currentMessages => [message, ...currentMessages]);
                                    }, index * 500);
                                });
                            } else if (replyMode === 'person') {
                                //逐字逐句展示
                                const displayCharByChar = (sentence, index, delay, callback) => {
                                    if (index < sentence.length) {
                                        const newMessage = sentence.slice(0, index + 1);
                                        setMessages(currentMessages => {
                                            const updatedMessages = [...currentMessages];
                                            updatedMessages[0] = {
                                                ...updatedMessages[0],
                                                content: newMessage,
                                                isTyping: true,
                                            };
                                            return updatedMessages;
                                        });
                                        setTimeout(() => displayCharByChar(sentence, index + 1, delay, callback), delay);
                                    } else {
                                        setMessages(currentMessages => {
                                            const updatedMessages = [...currentMessages];
                                            updatedMessages[0] = {
                                                ...updatedMessages[0],
                                                isTyping: false,
                                            };
                                            return updatedMessages;
                                        });
                                        callback();
                                    }
                                };


                                const displaySentenceBySentence = (messages, sentenceIndex, callback) => {
                                    if (sentenceIndex < messages.length) {
                                        const sentence = messages[sentenceIndex].content;
                                        const msgObject = messages[sentenceIndex];
                                        setMessages(currentMessages => [msgObject, ...currentMessages]);
                                        displayCharByChar(sentence, 0, 100, () => {
                                            setTimeout(() => displaySentenceBySentence(messages, sentenceIndex + 1, callback), 200);
                                        });
                                    } else {
                                        callback();
                                    }
                                };

                                displaySentenceBySentence(splitMsgObjects, 0, () => { });
                            }

                            return prevMessages;
                        }
                    }
                    return [msg, ...prevMessages];
                }

            });
            disableValue.current = false

        });
    };


    return (
        <Layout style={{ padding: "10px", width: "45vw" }}>
            <Space align='center'>
                <h1 style={{
                    height: '40px', // 固定高度
                    lineHeight: '40px', // 确保文本在容器内垂直居中
                    margin: 0 // 去除默认的外边距
                }}
                >聊天测试</h1>
                <Radio.Group
                    style={{
                        marginLeft: "10px"
                    }}
                    options={options}
                    size='small'
                    type='button'
                    onChange={(v) => setReplyMode(v)}
                    value={replyMode}
                />
            </Space>
            <Space direction={"vertical"} style={{ height: "82vh", overflowY: 'auto', padding: "10px" }} ref={contentRef}>
                {messages.length === 0 ? <Empty description='暂无聊天数据' /> :
                    messages.slice().reverse().map((msg, index) => (
                        <Space
                            className='chat-box'
                            key={index}
                            align={"start"}
                        >
                            <Avatar style={{ backgroundColor: '#366ef4' }}>
                                <img alt='avatar'
                                    src={msg.role === "user" ? "https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post" :
                                        "https://pic1.zhimg.com/70/v2-73543239e7ae941adf7d3a4d62385951_1440w.avis?source=172ae18b&biz_tag=Post"
                                    } />
                            </Avatar>
                            <Space direction={"vertical"}>
                                <Space direction='horizontal'>
                                    <Text>{msg.role === "user" ? "用户" : "销售"}</Text>
                                    {msg.created_at && (
                                        <Text type="secondary" style={{ fontSize: '12px' }}>
                                            {new Date(msg.created_at).toLocaleTimeString('en-GB', { hour12: false })}
                                        </Text>
                                    )}
                                </Space>
                                <Space align={"center"}>
                                    <div
                                        style={{
                                            background: msg.role === 'user' ? '#366ef4' : '#f7f7fa',
                                            color: msg.role === 'user' ? '#ffffff' : '#000000',
                                            padding: '10px',
                                            borderRadius: '20px',
                                            backgroundColor: msg.isTyping ? '#65B0F4' : msg.role === 'user' ? '#F7F7FA' : '#366EF4',
                                            display: 'inline-block',
                                            minWidth: "50px"
                                        }}
                                    >
                                        {msg.content.split(/(?<=[?!。])/).map((sentence, i) => (
                                            <Text key={i} style={{
                                                color: msg.role === 'user' ? 'black' : 'white',
                                                display: 'inline'
                                            }}>
                                                {sentence}
                                            </Text>
                                        ))}
                                    </div>
                                    {/* {msg.role === 'assistant' && index > 0 && (
                                        <Text style={{ color: '#366ef4', marginLeft: '8px', whiteSpace: 'nowrap' }}>
                                            {`${((new Date(msg.created_at)) - (new Date(messages[index - 1].created_at))) / 1000}s`}
                                        </Text>
                                    )} */}
                                </Space>
                            </Space>
                        </Space>
                    ))
                }
            </Space>


            <Space style={{ width: "80%", marginLeft: "1%", marginTop: "20px" }}>
                <Tooltip content="清空对话记录">
                    <Button type='text'
                        shape='circle'
                        onClick={newConversationHandler}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 16 16" fill="currentColor">
                            <path
                                d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z" />
                        </svg>}

                    />
                </Tooltip>
                <Input
                    disabled={disableValue.current}
                    value={inputValue}
                    onChange={(value) => setInputValue(value)}
                    onPressEnter={handleSend}
                    style={{ borderRadius: '25px', height: '50px', minWidth: "35vw" }}
                />
                {/*<Tooltip content="上传文件">*/}
                {/*    <Button*/}
                {/*        type="default"*/}
                {/*        icon={<IconPlusCircle style={{width:"20",height:"20"}} />}*/}
                {/*        onClick={handleUpload}*/}
                {/*        disabled*/}
                {/*    />*/}
                {/*</Tooltip>*/}
                <Tooltip content="发送聊天">
                    <Button
                        type={inputValue === '' ? "text" : "text"}
                        icon={<IconSend style={{ width: "20", height: "20" }} />}
                        onClick={handleSend}
                        disabled={inputValue === ''}
                    />
                </Tooltip>
            </Space>
        </Layout>
    );
};

export default ChatInterface;

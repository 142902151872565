import React from 'react';
import PlatformList from './components/PlatformList';
import RoleConfig from './components/RoleCofig';
import ChatInterface from './components/ChatInterface';
import { Layout } from '@arco-design/web-react';

const { Sider, Content } = Layout;
export const Sales = () => {
    return (
        <Layout>
            <Sider >
                <PlatformList />
            </Sider>
            <Layout>
                <Sider style={{ height: '100%', width: '40vw' }}>
                    <RoleConfig />
                </Sider>
                <Content>
                    <ChatInterface />
                </Content>
            </Layout>
        </Layout >
    )
}

// 基础配置
let modelServiceUrl = 'https://api.coze.cn';
let myToken = 'pat_2XatuvYDI3O9DUzXepy8q8MoMhNZ9icUa3dz9T2ax4VYK4tnQmAJmaSZURO4irdW';

const options = {
    headers: {
        'Authorization': `Bearer ${myToken}`,
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Host': 'api.coze.cn',
        'Connection': 'keep-alive'
    }
};

// 通用API请求函数
async function fetchApi(endpoint, method = 'POST', data = {}) {
    try {
        const response = await fetch(`${modelServiceUrl}${endpoint}`, {
            headers: {...options.headers},
            method: method,
            body: method === 'GET' ? null : JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        console.error(`Failed to fetch ${endpoint}:`, error);
        return null;
    }
}

// 获取机器人信息
export async function getBotInfo(bot_id = "7395919635352551464") {
    return fetchApi(`/v1/bot/get_online_info?bot_id=${bot_id}`, 'GET');
}

// 创建对话
export async function createConversation(messages) {
    const data = {
        "messages": messages
    };
    return fetchApi('/v1/conversation/create', 'POST', data);
}

// 获取对话消息列表
export async function getConversationMessages(conversation_id) {
    return fetchApi(`/v1/conversation/message/list?conversation_id=${conversation_id}`, 'POST');
}


// 发送聊天请求
export async function postChatBot(conversation_id, content, bot_id = "7395919635352551464") {
    const data = {
        "bot_id": bot_id,
        "user_id": "29032201866666",  // 这里根据你的情况更改
        "stream": false,
        "auto_save_history": true,
        "additional_messages": [
            {
                "role": "user",
                "content": content,
                "content_type": "text"
            }
        ],
    };
    return fetchApi(`/v3/chat?conversation_id=${conversation_id}`, 'POST', data);
}


// 查询对话结果
export async function chatRetrieve(conversation_id, chat_id) {
    return fetchApi(`/v3/chat/retrieve?conversation_id=${conversation_id}&chat_id=${chat_id}`, 'GET');
}